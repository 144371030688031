import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AuthenticationService } from './services/authService/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  public appPages = [
    {
      title: 'Home',
      url: '/home',
      icon: 'desktop'
    },
    {
      title: 'Restaurant-Übersicht',
      url: '/overview-restaurants',
      icon: 'cloudy'
    },
    {
      title: 'Tisch-Übersicht',
      url: '/overview-tables',
      icon: 'scan'
    },
    {
      title: 'Nutzer-Übersicht',
      url: '/overview-users',
      icon: 'people'
    }
  ];

  constructor(
    private platform: Platform,
    private authService: AuthenticationService,
    private translate: TranslateService,
    public afAuth: AngularFireAuth,
  ) {
    this.initializeApp();
    this.translate.addLangs(['en', 'de', 'nl']);
    this.translate.setDefaultLang('de');
    const browserLang = translate.getBrowserLang();
    this.translate.use(browserLang.match(/en|de|nl/) ? browserLang : 'de');
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      await StatusBar.setStyle({ style: Style.Default }).catch(() => { });
      await SplashScreen.hide();
    });
  }
  async logout() {
    this.authService.logout();
  }

  setCurrentUser(email: string) {
    sessionStorage.setItem('user', email);
  }
}
