// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


/** DEVELOPMENT!!!! */

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAgvVza5dVQQWyy9v0VvFY5tjL6vP2Yg3s",
    authDomain: "ordito-management-d.firebaseapp.com",
    databaseURL: "https://ordito-management-d.firebaseio.com",
    projectId: "ordito-management-d",
    storageBucket: "ordito-management-d.appspot.com",
    messagingSenderId: "410305795755",
    appId: "1:410305795755:web:57d13d7b24277278ebc9ee"
  }
};

/*
export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyBIP1xtgOAPoXXVUE9RMvGgSwPZ-QYiSfA',
    authDomain: 'ordito-management.firebaseapp.com',
    databaseURL: 'https://ordito-management.firebaseio.com',
    projectId: 'ordito-management',
    storageBucket: 'ordito-management.appspot.com',
    messagingSenderId: '232296760101',
    appId: '1:232296760101:web:181186ac50fa4b735090cc',
    measurementId: 'G-Y6XDRDDBRV'
  }
};
*/
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
