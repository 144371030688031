<ion-app>
  <ion-split-pane contentId="main-content">
    <ion-menu contentId="main-content" type="overlay" color="primary">
      <ion-header>
        <ion-toolbar color="primary">
            <ion-img src="../assets/logos/ordito/logo_white.png" [routerLink]="['/home']"></ion-img>
            <ion-title>Management Plattform</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content color="primary">
        
        <ion-list *ngIf="afAuth.authState | async as user"  >
          <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages">
            <ion-item [routerDirection]="'root'" [routerLink]="[p.url]" color="primary" > 
              <ion-icon slot="start" [name]="p.icon"></ion-icon>
              <ion-label>
                {{p.title}}
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
          <br>
          <ion-button (click)="logout()" color="secondary" expand="block">Logout</ion-button>
        </ion-list>
      </ion-content> 
      <ion-footer>
        <ion-toolbar color="primary">
          <div class="ion-padding">Version 2.0.1 (02.02.2021)</div>
        </ion-toolbar>
      </ion-footer>    
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
