import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { take } from 'rxjs/operators';
import { ManagementUser } from 'src/app/shared/shared_models/managementUser';
import { RestaurantOwner } from 'src/app/shared/shared_models/restaurantOwner';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private firestore: AngularFirestore) { }

  public getAllManagementUsers() {
    return this.firestore.collection('managementUsers').snapshotChanges();
  }

  public async getUserObject(uid: string, isManagementUser: boolean) {
    if (isManagementUser) {
      return this.firestore.collection<ManagementUser>('managementUsers').doc(uid).get();
    } else {
      return this.firestore.collection<RestaurantOwner>('restaurantOwners').doc(uid).get();
    }
  }

  public async updateManagementUserObject(uid: string, user: any) {
    await this.firestore.collection('managementUsers').doc(uid).update(user).then(data => {
      // console.log('update', data);
    });
  }  public async updateRestaurantUserObject(uid: string, user: any) {
    await this.firestore.collection('restaurantOwners').doc(uid).update(user).then(data => {
      // console.log('update', data);
    });
  }

  public async updateLoginHistory(userDocId: string, loginHistory: any) {
    await this.firestore.collection('managementUsers').doc(userDocId).update({loginHistory}).then(data => {
      // console.log('update', data);
    });
  }


  public async insertManagementUser(user: ManagementUser) {
    const date = new Date();
    const creator = sessionStorage.getItem('uid');
    return this.firestore.collection('managementUsers').doc(user.uid).set(user);
  }

  public getAllRestaurantOwners() {
    return this.firestore.collection('restaurantOwners').snapshotChanges();
  }

  public async insertRestaurantOwner(user: RestaurantOwner) {
    const date = new Date();
    const creator = sessionStorage.getItem('uid');
    return this.firestore.collection('restaurantOwners').doc(user.uid).set({
      uid: user.uid, email: user.email,
      firstName: user.firstName, lastName: user.lastName,
      createdAt: date, createdBy: creator, updatedAt: date, updatedBy: creator,
      loginHistory: [{ date: new Date() }]
    });
  }
}
