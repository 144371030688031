import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/authService/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./dashboard/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'overview-restaurants',
    loadChildren: () => import('./restaurants/overview-restaurants/overview-restaurants.module').then(m => m.OverviewRestaurantsPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'restaurant-details/:documentID',
    loadChildren: () => import('./restaurants/edit-restaurant-details/restaurant-details.module').then(m => m.RestaurantDetailsPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'overview-users', loadChildren: () => import('./users/overview-users-menu/overview-users.module').then(m => m.OverviewUsersPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'decide-edit-mode-restaurant/:documentID',
    // tslint:disable-next-line:max-line-length
    loadChildren: () => import('./restaurants/decide-menu-restaurant/decide-edit-mode-restaurant/decide-edit-mode-restaurant.module').then(m => m.DecideEditModeRestaurantPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'overview-menu/:restaurantDocumentID/:menuDocumentID',
    loadChildren: () => import('./menus/overviewMenu/overview-menu/overview-menu.module').then(m => m.OverviewMenuPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'management-users', loadChildren: () => import('./users/management-users/overview/management-users.module').then(m => m.ManagementUsersPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'restaurant-users', loadChildren: () => import('./users/restaurant-users/overview/restaurant-users.module').then(m => m.RestaurantUsersPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'add-user/:decideWhichUserToAdd', loadChildren: () => import('./users/restaurant-users/add-user/add-user.module').then(m => m.AddUserPageModule),
    canActivate: [AuthGuardService]
  },
  {
    // tslint:disable-next-line:max-line-length
    path: 'edit-restaurant-user/:restaurantUserDocumentID', loadChildren: () => import('./users/restaurant-users/edit-restaurant-user/edit-restaurant-user.module').then(m => m.EditRestaurantUserPageModule),
    canActivate: [AuthGuardService]
  },
  {
    // tslint:disable-next-line:max-line-length
    path: 'decide-edit-mode-management-users/:managementUserDocumentID', loadChildren: () => import('./users/management-users/decide-edit-mode-management-users/decide-edit-mode-management-users.module').then(m => m.DecideEditModeManagementUsersPageModule),
    canActivate: [AuthGuardService]
  },

  {
    // tslint:disable-next-line:max-line-length
    path: 'decide-edit-mode-restaurant-users/:restaurantUserDocumentID', loadChildren: () => import('./users/restaurant-users/decide-edit-mode-restaurant-users/decide-edit-mode-restaurant-users.module').then(m => m.DecideEditModeRestaurantUsersPageModule),
    canActivate: [AuthGuardService]
  },
  {
    // tslint:disable-next-line:max-line-length
    path: 'show-history-management-user/:managementUserDocumentID', loadChildren: () => import('./users/management-users/show-history-management-user/show-history-management-user.module').then(m => m.ShowHistoryManagementUserPageModule),
    canActivate: [AuthGuardService]
  },
  {
    // tslint:disable-next-line:max-line-length
    path: 'edit-management-user/:managementUserDocumentID', loadChildren: () => import('./users/management-users/edit-management-user/edit-management-user.module').then(m => m.EditManagementUserPageModule),
    canActivate: [AuthGuardService]
  },
  { // tslint:disable-next-line:max-line-length
    path: 'show-history-restaurant-user/:restaurantUserDocumentID', loadChildren: () => import('./users/restaurant-users/show-login-history-restaurant-user/show-login-history-restaurant-user.module').then(m => m.ShowLoginHistoryRestaurantUserPageModule),
    canActivate: [AuthGuardService]
  },
  { path: 'tables/:documentID',
    loadChildren: () => import('./tables/tables.module').then(m => m.TablesPageModule),
      canActivate: [AuthGuardService]  },
  { path: 'table-details/:tableID',
    loadChildren: () => import('./tables/table-details/table-details.module').then(m => m.TableDetailsPageModule),
      canActivate: [AuthGuardService]  },
  { path: 'overview-tables', loadChildren: () => import('./tables/overview-tables/overview-tables.module').then(m => m.OverviewTablesPageModule),
      canActivate: [AuthGuardService]  },
  { path: 'show-topping-lists/:restaurantDocumentID',
      loadChildren: () => import('./restaurants/show-topping-lists/show-topping-lists.module').then(m => m.ShowToppingListsPageModule),
      canActivate: [AuthGuardService] },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
