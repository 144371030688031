import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService  {

  private helper = new JwtHelperService();

  constructor(public authenticationService: AuthenticationService, private router: Router) {}

  canActivate(): boolean {
    // console.log('AuthGuardService', sessionStorage.getItem('uid'));
    if (sessionStorage.getItem('uid') === null || this.helper.isTokenExpired(sessionStorage.getItem('token'))) {
      this.authenticationService.logout();
      // this.router.navigate(['/']);
    } else {
      // console.log(this.helper.isTokenExpired(sessionStorage.getItem('token')));
      return true;
    }
  }
}
