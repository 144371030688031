import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { ToastService } from '../toastService/toast.service';
import { UserService } from '../userService/user.service';

import { JwtHelperService } from '@auth0/angular-jwt';
import { ManagementUser } from 'src/app/shared/shared_models/managementUser';
import { RestaurantOwner } from 'src/app/shared/shared_models/restaurantOwner';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private helper = new JwtHelperService();
  loggedIn = false;
  authState = new BehaviorSubject(false);

  constructor(private toastService: ToastService, private userService: UserService,
    public afAuth: AngularFireAuth, private router: Router) {
    this.loggedIn = !sessionStorage.getItem('uid');
    // console.log('constructor loggedIn:', this.loggedIn);
  }

  public async logout() {
    await this.afAuth.signOut()
      .catch(error => { });
    this.setCurrentUser(null);
    sessionStorage.clear();
    this.router.navigate(['/']);
  }

  public async login(email: string, password: string) {
    await this.afAuth.signInWithEmailAndPassword(email, password).then(result => {
      const uid = result.user.uid;
      this.userService.getUserObject(uid, true).then(data => {
        data.subscribe(async userRawData => {
          const user = userRawData.data();
          const loginHistory = user.loginHistory;
          loginHistory.push({ date: new Date() });
          this.userService.updateLoginHistory(uid, loginHistory);
          const token = await result.user.getIdToken();
          sessionStorage.setItem('token', token);
          sessionStorage.setItem('uid', uid);
        });
      });
    }).catch(error => {
      this.toastService.showToast('Wrong/Invalid Email/Password');
    });
    this.setCurrentUser(email);
  }

  public async register(email: string, password: string, firstName: string, lastName: string) {
    return await this.afAuth.createUserWithEmailAndPassword(email, password)
      .then(resultOfRegister => {
        const user: ManagementUser = {
          admin: true,
          uid: resultOfRegister.user.uid,
          email: resultOfRegister.user.email,
          firstName,
          lastName,
          createdAt: new Date(),
          createdBy: sessionStorage.getItem('uid'),
          updatedAt: new Date(),
          updatedBy: sessionStorage.getItem('uid')
        }
        return user;
      });
  }

  private setCurrentUser(email: string) {
    sessionStorage.setItem('user', email);
    this.loggedIn = true;
  }

  public sendResetPasswordMail(email: string) {
    this.afAuth.sendPasswordResetEmail(email).then(data => {
      console.log(data);
    });
  }

}
